import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const JIS = 'https://static.chinhdaisteel.com/quality_certification/jis.png';
const ASTM = 'https://static.chinhdaisteel.com/quality_certification/astm.png';
const ISO = 'https://static.chinhdaisteel.com/quality_certification/ISO 9001_v2.webp';
const AUS = 'https://static.chinhdaisteel.com/quality_certification/aus_white.png';
const SIRIM = 'https://static.chinhdaisteel.com/quality_certification/sirim.webp';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundImage: `url(${'https://static.chinhdaisteel.com/background/quality_homepage.jpg'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'overlay',
    backgroundColor: '#444',
    [theme.breakpoints.up('lg')]: {
      marginTop: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '3rem',
    },
  },
  container: {
    marginTop: '3rem',
  },
  item: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '1rem 1rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem 1.5rem 2rem 1.5rem',
      marginTop: '3rem',
      marginBottom: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
      padding: '5px 14px 5px 14px',
    },
  },
  itemIso: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '1rem 1rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '4rem',
      marginBottom: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
      padding: '5px 14px 5px 14px',
    },
  },
  image: {
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      maxWidth: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      height: 'auto',
      maxWidth: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      maxWidth: '50%',
    },

  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#fff',
  },
  content: {
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '1.14rem',
    marginBottom: theme.spacing(2),
    color: '#fff',
  },
  subtittleSpacing: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    color: '#888',
    fontSize: '1.14rem',
    paddingTop: '1rem',
  },
  marginLogoJis: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: '15px',
    },
  },
  marginLogoIso: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: '10px',
    },
  },
}));

function qualityHomeVn() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid container className={classes.container} justify="center" alignItems="center">
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}> CHỨNG CHỈ CHẤT LƯỢNG </Typography>
          <Typography align="center" style={{ color: '#fff' }} className={classes.subtittleSpacing}> Chinh Dai Industrial Co., Ltd </Typography>
        </Grid>
        <Grid item xs={1} sm={2} lg={2} />
        <Grid item container xs={10} sm={8} md={8} lg={8}>
          <Grid item xs={12} md={2} sm={2} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={AUS}
                alt="graph"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={2} sm={2} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={ASTM}
                alt="suitcase"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={2} sm={2} lg={2} xl={2} className={classes.marginLogoIso}>
            <div className={classes.itemIso}>
              <img
                style={{ marginRight: '20px' }}
                className={classes.image}
                src={ISO}
                alt="clock"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={2} sm={2} lg={2} xl={2} className={classes.marginLogoJis}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={JIS}
                alt="clock"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={2} sm={2} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                style={{ width: '65%' }}
                className={classes.image}
                src={SIRIM}
                alt="suitcase"
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={2} lg={1} />
      </Grid>
    </section>
  );
}

export default withStyles(useStyles)(qualityHomeVn);
