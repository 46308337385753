import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import backgroundImageWeb from '../../images/winfire-banner.jpg';
// import backgroundImageMobile from '../../images/cdiimageMobile.svg';

const useStyles = makeStyles((theme) => ({
  background: {
    // backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundImageWeb})`,
      height: '30vh',
      marginTop: '4.5rem',
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${backgroundImageWeb})`,
      height: '66vh',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${backgroundImageWeb})`,
      height: '100vh',
    },
  },
}));

function Banner() {
  const classes = useStyles();

  return (
    <>
      <section className={classes.background} />
    </>
  );
}

export default Banner;
