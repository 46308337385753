import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const value1img = 'https://static.chinhdaisteel.com/icons/flag.png';
const value2img = 'https://static.chinhdaisteel.com/icons/star.png';
const value3img = 'https://static.chinhdaisteel.com/icons/cycle.png';
const value4img = 'https://static.chinhdaisteel.com/icons/like.png';
const CoreValueBackground = 'https://static.chinhdaisteel.com/background/corevalue.png';
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundImage: `url(${CoreValueBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  item: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '1rem 1rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem 1.5rem 2rem 1.5rem',
      marginTop: '3rem',
      marginBottom: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px 14px 5px 14px',
      marginTop: '1rem',
    },
  },
  image: {
    height: 'auto',
    width: '66px',
    marginTop: '1rem',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.14rem',
    color: '#fff',
  },
  content: {
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '1.14rem',
    marginBottom: theme.spacing(2),
    color: '#fff',
  },
}));

function CoreValue() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid container>
        <Grid item lg={1} />
        <Grid item container lg={10} spacing={4}>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={value3img}
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                CHẤT LƯỢNG QUỐC TẾ
              </Typography>
              <Typography variant="h5" className={classes.content}>
                Dẫn đầu Đông Nam Á trong hoạt động xuất khẩu thép ống hộp đến thị
                trường Úc, Hoa Kỳ, Ấn Độ.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={value1img}
                alt="suitcase"
              />
              <Typography variant="h6" className={classes.title}>
                20+ NĂM KINH NGHIỆM
              </Typography>
              <Typography variant="h5" className={classes.content}>
                Thép Chính Đại với nhiều năm kinh nghiệm trong ngành,
                cùng lượng khách hàng đông đảo.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={value2img}
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                DÂY CHUYỀN HIỆN ĐẠI
              </Typography>
              <Typography variant="h5" className={classes.content}>
                Công nghệ máy móc hiện đại từ Châu Âu, Nhật Bản, Hàn Quốc…
                đảm bảo chất lượng ISO 9001:2015.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={3} xl={3}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={value4img}
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                SẢN PHẨM ĐA DẠNG
              </Typography>
              <Typography variant="h5" className={classes.content}>
                Sản phẩm có chất lượng vượt trội, bắt nhịp xu hướng thế giới
                và thỏa mãn yêu cầu cụ thể của từng khách hàng.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item lg={1} />
      </Grid>
    </section>
  );
}

export default CoreValue;
