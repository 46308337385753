import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  textWeb: {
    fontSize: '1.14rem',
    fontFamily: [
      'Roboto', 'Helvetica Neue', 'Arial',
    ].join(','),
    textTransform: 'uppercase',
  },

  gridSubject: {
    borderLeft: '3px solid #0068ff',
  },
  mainGrid: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  imageSlider: {
    width: '100%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  subTitle: {
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    color: '#888',
    fontSize: '1.14rem',
    paddingTop: '1rem',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}));

export default function Congtrinh() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container maxWidth="md">
        <main>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={1} md={1} lg={1} />
            <Grid item xs={10} md={10} lg={10}>
              <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}>
                CÁC CÔNG TRÌNH TIÊU BIỂU
              </Typography>
              <Typography align="center" className={classes.subTitle}>
                Một vài các công trình tiêu biểu sử dụng sản phẩm và giải pháp của Winfire
              </Typography>
            </Grid>
            <Grid item xs={1} md={1} lg={1} />
            <Grid item xs={12} md={12} lg={12}>
              <Container className={classes.sliderContainer}>
                <Carousel className={classes.imageSlider}>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/le-van-luong-tower.jpg" className={classes.image} />
                    <p className="legend" style={{ opacity: '1' }}>
                      {' '}
                      <p className={classes.textWeb}> BRG Tower Le Van Luong </p>
                      {' '}
                    </p>
                  </div>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/ava-sunshine-city-21-1000x562.jpg" className={classes.image} />
                    <p className="legend">
                      {' '}
                      <p className={classes.textWeb}>Sunshine City</p>
                    </p>
                  </div>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/hoa-lam-1000x562.jpg" className={classes.image} />
                    <p className="legend">
                      {' '}
                      <p className={classes.textWeb}>Hoa Lam International Hospital</p>
                    </p>
                  </div>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/sheraton-da-nang-1000x562.jpg" className={classes.image} />
                    <p className="legend">
                      {' '}
                      <p className={classes.textWeb}> Sheraton Hotel Danang </p>
                    </p>
                  </div>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/park-hill.jpg" className={classes.image} />
                    <p className="legend">
                      {' '}
                      <p className={classes.textWeb}> Vinhomes Park Hill</p>
                    </p>
                  </div>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/du-an-flc-sam-son-1-1000x562.jpg" className={classes.image} />
                    <p className="legend">
                      {' '}
                      <p className={classes.textWeb}>FLC Sam Son</p>
                    </p>
                  </div>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/phoi-canh-du-an-imperia-sky-garden-1000x636.jpg" className={classes.image} />
                    <p className="legend">
                      {' '}
                      <p className={classes.textWeb}> Imperial Sky Garden Minh Khai </p>
                      {' '}
                    </p>
                  </div>
                  <div>
                    <img alt="" src="https://static.chinhdaisteel.com/cong-trinh/Hateco-plaza-1000x600.png" className={classes.image} />
                    <p className="legend">
                      {' '}
                      <p className={classes.textWeb}> Hateco Plaza </p>
                      {' '}
                    </p>
                  </div>
                </Carousel>
              </Container>
            </Grid>
          </Grid>
        </main>
      </Container>
    </section>
  );
}
