import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Ongden from '../../images/ong-den-chuyen-dung.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    display: 'flex',
    position: 'relative',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
  },
  header2: {
    fontSize: '1.14rem',
    color: '#888888',
    marginBottom: theme.spacing(5),
  },
  textFont: {
    marginTop: '1rem',
    fontSize: '1.14rem',
    fontWeight: 'bold',
    textDecoration: 'none !important',
  },
  textFont1: {
    fontSize: '0.875rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(5),
    testAlign: 'center',
    fontFamily: [
      'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    fontSize: '1.14rem',
    textDecoration: 'none',
    padding: '1rem 1.5rem 1rem 1.5rem',
    borderRadius: '41px',
    backgroundColor: '#f2ae1c',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 1rem 1rem 1rem',
    },
  },
  quotationContainer: {
    width: '100%',
    marginTop: '6rem',
    height: 'auto',
    backgroundColor: 'rgb(31,54,92)',
  },
}));

function ProductMenu() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}>
              GÓI SẢN PHẨM VÀ GIẢI PHÁP
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <a href="/san-pham/ong-co-ren-ranh" style={{ textDecoration: 'none' }}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Ống có ren rãnh"
                    height="263"
                    image="https://static.chinhdaisteel.com/Winfire/shuttlerstock-image/shutterstock_745698511.jpg"
                    title="Ống có ren rãnh"
                  />
                </CardActionArea>
              </Card>
            </a>
            <br />
            <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center">
              <a href="/san-pham/ong-co-ren-ranh" className={classes.actionButton}> Gói ống sơn, tạo sẵn ren - rãnh </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <a href="/san-pham/ong-den-chuyen-dung" style={{ textDecoration: 'none' }}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Ống đen chuyên dụng"
                    height="263"
                    image={Ongden}
                    title="Ống đen chuyên dụng"
                  />
                </CardActionArea>
              </Card>
            </a>
            <br />
            <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center">
              <a href="/san-pham/ong-den-chuyen-dung" className={classes.actionButton}> Ống đen và Mạ kẽm chuyên dụng </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <a href="/san-pham/san-sang-thi-cong" style={{ textDecoration: 'none' }}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt=" Sẵn sàng thi công"
                    height="263"
                    image="https://static.chinhdaisteel.com/Winfire/shuttlerstock-image/shutterstock_492786013.jpg"
                    title=" Sẵn sàng thi công"
                  />
                </CardActionArea>
              </Card>
            </a>
            <br />
            <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center">
              <a href="/san-pham/san-sang-thi-cong" className={classes.actionButton}> Sẵn sàng thi công </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.quotationContainer}>
        <Grid container>
          <Grid item xs={1} lg={1} />
          <Grid item xs={10} lg={10}>
            <br />
            <Typography variant="h6" align="center" style={{ marginTop: '3rem', color: 'white' }}>
              Công ty TNHH Công Nghiệp Chính Đại với 20 năm kinh nghiệm trong
              lĩnh vực sản xuất các loại ống thép, đi đầu trong việc cung cấp ống thép
              cứu hỏa chuyên dụng ở Việt Nam
            </Typography>
            <br />
            <Typography variant="h4" align="center" style={{ color: 'white' }}>
              NHẬN BÁO GIÁ NGAY BÂY GIỜ
            </Typography>
            <br />
            <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center" style={{ marginBottom: '5rem' }}>
              <a href="/lien-he" className={classes.actionButton}> Liên hệ ngay với chúng tôi </a>
            </Typography>
          </Grid>
          <Grid item xs={1} lg={1} />
        </Grid>
      </div>
    </section>
  );
}

export default withStyles(useStyles)(ProductMenu);
