import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/navbar/navbarvn';
import Banner from '../components/homepagevn/banner';
import CoreValues from '../components/homepagevn/corevalues';
import GeneralIntro from '../components/homepagevn/generalintro';
import Product from '../components/homepagevn/products';
import NotableProject from '../components/homepagevn/notableproject';
import QualityCertification from '../components/homepagevn/qualitycertification';
import Footer from '../components/homepagevn/Footer';
import Contact from '../components/homepagevn/contact';
import '../style/index.css';

const IndexPage = () => (
  <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title> WINFIRE</title>
    </Helmet>
    <Navbar />
    <Banner />
    <Product />
    <NotableProject />
    <CoreValues />
    <GeneralIntro />
    <QualityCertification />
    <Contact />
    <Footer />
  </>
);

export default IndexPage;
