import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ReactPlayer from 'react-player';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
    display: 'flex',
    position: 'relative',
  },
  content: {
    fontSize: '1.14rem',
  },
  title: {
    marginTop: theme.spacing(5),
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  header2: {
    fontSize: '1.14rem',
    color: '#888888',
  },
  text1: {
    fontSize: '1.14rem',
    fontWeight: 'bold',
  },
  text2: {
    fontSize: '1.14rem',
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contenWidth: {
    width: '60%',
  },
  linkStyle: {
    fontSize: '1.14rem',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    textDecoration: 'none',
    color: '#3f51b5',
  },
}));

function ProductIntro() {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}> GIỚI THIỆU VỀ WINFIRE </Typography>
            <Typography align="center" className={classes.header2}> Chinh Dai Industrial Co., Ltd </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} className={classes.videoContainer}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=J5_i_fB6plQ&t=8s"
              width="100%"
              height="400px"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={5}>
            <Typography className={classes.content}>
              <b> Công ty TNHH Công Nghiệp Chính Đại (Chinh Dai Industrial Co., Ltd) </b>
              chuyên Sản xuất và Kinh doanh các loại Thép xây dựng, Thép cuộn cán nóng,
              Thép cuộn cán nguội, Thép mạ, Thép hình hộp các loại.
            </Typography>
            <br />
            <Typography className={classes.content}>
              Công ty chúng tôi được thành lập với sứ mệnh mang đến cho Quý Khách Hàng các
              sản phẩm chất lượng cao với giá cả cạnh tranh và dịch vụ tốt nhất. Trải qua
              nhiều năm xây dựng và liên tục mở rộng, đổi mới, Chính Đại đã trở thành một
              trong những nhà sản xuất thép ống lớn trong khu vực. Nhiều công trình trọng
              điểm và các dự án lớn đã và đang sử dụng sản phẩm của chúng tôi.
            </Typography>
            <br />
            <a href="/gioi-thieu" className={classes.linkStyle}> Tìm hiểu thêm về chúng thôi » </a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withStyles(useStyles)(ProductIntro);
